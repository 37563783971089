import React from "react";
import { Card, CardContent, Typography, Box } from "@mui/material";
// import RocketLaunchIcon from "@mui/icons-material/RocketLaunch";
import "./Roadmap.css";
import flight from '../../assets/images/rocket01.png';

const RoadmapCard = ({ title, description, listItems, index }) => {
  // Determine whether to apply cardLeft or cardRight class based on the index
  const cardClass = index % 2 === 0 ? "cardLeft" : "cardRight";

  return (
    <Card
      className={`cardStyle ${cardClass}`}
      sx={{
        background: 'linear-gradient(145deg, #AA0B61, #ab47bc)',
        boxShadow: '0 0 20px 10px rgba(170, 11, 97, 0.3)',
        borderRadius: "16px"
      }}
    >
      <Box className="headerStyle">{title}</Box>
      <CardContent className="cardContentStyle">
        <Typography  sx={{ marginBottom: "8px",fontFamily:"Comic Sans MS",paddingLeft:"10px" }}>
          {description}
        </Typography>
  
      </CardContent>
      <Box className="footerStyle">
     
        <img
          src={flight}
          alt="Rocket Flight"
          style={{ width: "32px", height: "32px", marginLeft: "85px" }}
          className="rocketIcon"
        />
    
        {/* <RocketLaunchIcon sx={{ color: "#00ff00", fontSize: "32px" ,marginLeft:"85px"}} className="rocketIcon" /> */}
      </Box>
    </Card>
  );
};

export default RoadmapCard;
