import React, { useState } from 'react';
import './Header.css';
import logo from '../../assets/images/cat5-01-01-01iii.png';

const Header = ({ onNavigate }) => {
  const [menuOpen, setMenuOpen] = useState(false);

  return (
    <header className="header">
      <div className="header-content">
        {/* Left Section: Logo and Brand Name */}
        <div className="logo-brand">
  <div className="logo">
    <img src={logo} alt="AiJokerCat Logo" />
  </div>
  <div className="brand-text">
    <span className="brand-name">
      <span className="ai-text">AI</span>
      <span className="jokercat-text">JOKERCAT</span>
    </span>
  </div>
</div>


        {/* Navigation Links */}
        <nav className={`navigation ${menuOpen ? 'open' : ''}`}>
          <a href="#about" className="nav-link">
            <span className="highlight">Ab</span><span>out</span>
          </a>
          <div className="divider"></div>
          <a href="#how-to-buy" className="nav-link">
            <span className="highlight">How</span> to <span className="highlight">Buy</span>
          </a>
          <div className="divider"></div>
          {/* <a href="#dextools" className="nav-link">
            <span className="highlight">Dex</span>Tools
          </a>
          <div className="divider"></div> */}
          <a href="#roadmap" className="nav-link">
            <span className="highlight">Road</span>map
           
          </a>
          <div className="divider"></div>
        </nav>

        {/* Right Section: Buy Now Button */}
        <button className="buy-now-button">BUY NOW</button>

        {/* Mobile Menu Toggle */}
        <button className="menu-toggle" onClick={() => setMenuOpen(!menuOpen)}>
          ☰
        </button>
      </div>
    </header>
  );
};

export default Header;


