import React, { useState, useEffect } from "react";
import "./HowToBuy.css";
import mobileImage from "../../assets/images/hob_mobile.png";
import innerImage from "../../assets/images/inner elements (4).png";

const HowToBuy = () => {
  const [isVisible, setIsVisible] = useState(false);
  const [isMobile, setIsMobile] = useState(false);
  const [isCopied, setIsCopied] = useState(false);

  const linkToCopy =
    "F3iEMbQ57kKYThTx5ZMgtDYenLmLvfamEsksycCpump";

  useEffect(() => {
    const handleVisibility = () => {
      const section = document.querySelector(".howtobuy-section");
      if (section) {
        const rect = section.getBoundingClientRect();
        const isInViewport = rect.top < window.innerHeight && rect.bottom > 0;
        setIsVisible(isInViewport);
      }
    };

    const handleResize = () => {
      setIsMobile(window.innerWidth <= 500);
    };

    window.addEventListener("scroll", handleVisibility);
    window.addEventListener("resize", handleResize);

    handleVisibility();
    handleResize();

    return () => {
      window.removeEventListener("scroll", handleVisibility);
      window.removeEventListener("resize", handleResize);
    };
  }, []);

  const handleImageClick = () => {
    // Copy the link to clipboard
    navigator.clipboard
      .writeText(linkToCopy)
      .then(() => {
        // Show copied feedback
        setIsCopied(true);

        // Reset the copied state after 2 seconds
        setTimeout(() => {
          setIsCopied(false);
        }, 2000);
      })
      .catch((err) => {
        console.error("Failed to copy: ", err);
      });
  };

  return (
    <div className="howtobuy-section">
      <div className="howtobuy-bg-image">
        <div
          className={`howtobuy-inner-image ${
            isVisible ? "howtobuy-visible" : ""
          }`}
          onClick={handleImageClick}
          style={{ cursor: "pointer" }}
        >
          <img
            src={isMobile ? mobileImage : innerImage}
            alt="Inner"
            className="howtobuy-image"
          />

          {isCopied && (
            <div
              className="copy-feedback"
              style={{
                position: "absolute",
                bottom: "60px",
                left: "50%",
                transform: "translateX(-50%)",
                backgroundColor: "rgba(0,0,0,0.7)",
                color: "white",
                padding: "10px",
                borderRadius: "5px",
                transition: "opacity 0.3s ease",
              }}
            >
              Link Copied!
            </div>
          )}
        </div>
      </div>
    </div>
  );
};

export default HowToBuy;