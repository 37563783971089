import React from "react";
import "./Banner.css"; // Importing the corresponding CSS file
import facebookIcon from "../../assets/images/planets.png"; // Background Image

const Banner = () => {
  return (
    <section className="banner-section" style={{ backgroundImage: `url(${facebookIcon})` }}>
      <div className="banner-card">
        <p className="banner-text">
          <span className="highlight-text">$AIJokerCat</span> is the first meme built by Silicon Valley AI engineers,<br />
          blending cutting-edge tech with hilarious crypto humor. Uniquely offering <br />
          AI-generated voice-response jokes, <span className="highlight-text">$AIJokerCat</span>  revolutionizes meme coins with <br />
          interactive engagement. More than a token, it’s a movement for traders who love profits<br />
          and laughs. Meow!
        </p>
      </div>
    </section>
  );
};

export default Banner;
