// Roadmap.js
import React, { useEffect, useRef } from "react";
import "./Roadmap.css";
import headingImage from "../../assets/images/bgnewwww (2).png";
import catImage from "../../assets/images/cat 4.0.png";
import RoadmapCard from "./RoadmapCard";
import { Typography } from '@mui/material';
const Roadmap = () => {
  const sectionRef = useRef(null);

  useEffect(() => {
    const section = sectionRef.current;
    const heading = section.querySelector(".roadmap-heading-image");
    const steps = section.querySelectorAll(".roadmap-step");

    const observer = new IntersectionObserver(
      (entries) => {
        entries.forEach((entry) => {
          if (entry.isIntersecting) {
            heading.classList.add("visible");
            steps.forEach((step) => step.classList.add("visible"));
          }
        });
      },
      { threshold: 0.3 }
    );

    observer.observe(section);

    return () => {
      observer.disconnect();
    };
  }, []);

  const roadmapData = [
    {
      title: "PHASE 1",
      description: (
        <>
          <Typography
            variant="body1"
           
            sx={{
              color: "white",
             
              lineHeight: "1.6",
              fontSize: "16px",
              textAlign: "left",
              fontFamily:"Comic Sans MS"
            }}
          >
            <a
              href="https://x.com"
              target="_blank"
              rel="noopener noreferrer"
              style={{ color: "#00FF00", textDecoration: "underline" , fontFamily:"Comic Sans MS"}}
            >
              X.com
            </a>{" "}
            mention interaction via voice.
          </Typography>
          <Typography
            variant="body1"
            
            sx={{
              color: "#00FF00",
              fontWeight: "bold",
              marginBottom: "16px",
              lineHeight: "1.6",
              fontSize: "16px",
              textAlign: "left",
               fontFamily:"Comic Sans MS"
            }}
          >
            We are the first in the universe to do this!
          </Typography>
          <Typography
            variant="body1"
            sx={{
              color: "white",
              marginBottom: "16px",
              lineHeight: "1.6",
              fontSize: "16px",
              textAlign: "left",
               fontFamily:"Comic Sans MS"
            }}
          >
            Enhance JokerCat personality by learning from audience engagement
          </Typography>
          <Typography
            variant="body1"
            sx={{
              color: "white",
              lineHeight: "1.6",
              fontSize: "16px",
              textAlign: "left",
              fontFamily:"Comic Sans MS",
            
            }}
          >
            Enhance voice quality
          </Typography>
        </>
      ),
    },
    {
      title: "PHASE 2",
      description: (
        <>
          <Typography
            variant="body1"
             component="div"
            sx={{
              color: "white",
             
            
              fontSize: "16px",
              textAlign: "left",
              fontFamily:"Comic Sans MS"
            }}
          >
            Humorous overview of the latest headlines in the world{" "}
            <span style={{ color: "#00FF00", fontFamily:"Comic Sans MS"}}>
              (no more negative news! We're tired of it!)
            </span>
            <span style={{ fontFamily:"Comic Sans MS"}}>
            . A bunch of people prefer their news to be funny. Think *Daily Show*.</span>
          </Typography>
          <Typography
            variant="body1"
             component="div"
            sx={{
              color: "#00FF00",

              fontSize: "16px",
              textAlign: "left",
               fontFamily:"Comic Sans MS"
            }}
          >
            ANOTHER First of its kind <span>AI interaction </span> </Typography>
            <Typography style={{ color: "white",fontFamily:"Comic Sans MS",textAlign:"left"}}>enhancements (To be revealed
            soon. We are already working </Typography>
            <Typography style={{textAlign:"left"}}> on these) - <span style={{ color: "#00FF00",fontFamily:"Comic Sans MS",textAlign:"left"}}>MOONSHOT!</span></Typography>
         
         
        </>
      ),
    },
    {
      title: "PHASE 3",
      description: (
        <>
          <Typography
            variant="body1"
             component="div"
            sx={{
              color: "white",
              marginBottom: "16px",
              lineHeight: "1.6",
              fontSize: "16px",
              textAlign: "left",
              fontFamily:"Comic Sans MS"
            }}
          >
            Instagram AR Filter. Transform into your favorite Joker!
          </Typography>
          <Typography
            variant="body1"
             component="div"
            sx={{
            
              lineHeight: "1.6",
              fontSize: "16px",
              textAlign: "left",
              fontFamily:"Comic Sans MS",
              color:"white"
            }}
          >
            Enhance AI engagement and make interactions more fun, personalized,
            and 
          </Typography>
          <Typography style={{color: "#00FF00",textAlign:"left"}}>ENTERTAINING!</Typography>
        </>
      ),
    },
    {
      title: "PHASE 4",
      description: (
        <>
          <Typography
            variant="body1"
            sx={{
              marginBottom: "16px",
              lineHeight: "1.6",
              fontSize: "16px",
              textAlign: "left",
              fontFamily:"Comic Sans MS"
            }}
          >
            <span style={{ color: "white" , fontFamily:"Comic Sans MS"}}>Collaborations with </span>
            <span style={{ color: "#00FF00" , fontFamily:"Comic Sans MS"}}>LINED UP</span>
            <div style={{ color: "white", fontFamily:"Comic Sans MS" }}>influencers</div>
          </Typography>
          <Typography
            variant="body1"
            sx={{
              color: "white",
            
              lineHeight: "1.6",
              fontSize: "16px",
              textAlign: "left",
              fontFamily:"Comic Sans MS"
            }}
          >
            Sponsorships from like-minded 
            <Typography>brands</Typography>
          </Typography>
        </>
      ),
    },
  ];

  return (
    <div className="roadmap-how-to-buy-section" ref={sectionRef} id="roadmap">
      <img src={headingImage} alt="How To Buy" className="roadmap-heading-image" />

      <div className="roadmap-center-cat">
        <img src={catImage} alt="Cat" className="roadmap-cat-image" />
      </div>

      <div className="roadmap-steps-container">
        {roadmapData.map((data, index) => (
          <div
            key={index}
            className={`roadmap-step roadmap-step${index + 1} ${
              index % 2 === 0 ? "roadmap-left" : "roadmap-right"
            }`}
          >
            <RoadmapCard title={data.title} description={data.description} index={index} />
          </div>
        ))}
      </div>
    </div>
  );
};

export default Roadmap;
