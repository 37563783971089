import ParticleBackground from './ParticleSnowEffect';
import './HeroSection.css';
import heroImage from '../../assets/images/cat canvas small.png';
import twitterIcon from '../../assets/images/twww.png'; // Twitter icon path
import telegramIcon from '../../assets/images/tgg.png'; // Telegram icon path
import buyNowIcon from '../../assets/images/buy 5.0.png'; // Buy Now icon path
import contactUsIcon from '../../assets/images/ett.png'; // Contact Us icon path
import videoSource from '../../assets/images/background-new.mp4'; // Ensure correct video path

const HeroSection = () => {
  return (
    <div className="herosection1-hero-section">
      {/* Video background */}
      <video autoPlay loop muted playsInline className="herosection1-background-video">
        <source src={videoSource} type="video/mp4" />
        Your browser does not support the video tag.
      </video>

      {/* Particle Effect */}
      <ParticleBackground />

      {/* Hero Content */}
      <div className="herosection1-hero-content">
        <div className="herosection1-center-container">
          {/* Buttons on the left */}
          <div className="herosection1-buttons herosection1-buttons-left">
            <a href="https://buy-now-link.com" target="_blank" rel="noopener noreferrer">
              <img src={contactUsIcon} alt="Buy Now" className="herosection1-button-icon" />
            </a>
            <a href="https://x.com/AIJokerCat" target="_blank" rel="noopener noreferrer">
              <img src={twitterIcon} alt="Twitter" className="herosection1-button-icon" />
            </a>
          </div>

          {/* Centered Cat Image */}
          {/* <img src={heroImage} alt="Hero" className="herosection1-hero-image" /> */}

          {/* Buttons on the right */}
          <div className="herosection1-buttons herosection1-buttons-right">
            <a href="https://t.me/aijokercat" target="_blank" rel="noopener noreferrer">
              <img src={telegramIcon} alt="Telegram" className="herosection1-button-icon" />
            </a>
            <a href="https://contact-us-link.com" target="_blank" rel="noopener noreferrer">
              <img src={buyNowIcon} alt="Contact Us" className="herosection1-button-icon" />
            </a>
          </div>
        </div>
      </div>
    </div>
  );
};

export default HeroSection;
