import './App.css';
import React from 'react';
import Header from './components/Header/Header';
// import HeroSection from './components/HeroSection/HeroSection';
import HeroSection1 from './components/HeroSection1/HeroSection';
import MovingStrip from './components/MovingStrip/MovingStrip';
import WhoWeAre from './components/WhoWeAre/WhoWeAre';
// import cloudImage from './assets/images/clound.avif';
// // import cloudImage from './assets/images/badal.png';
import HowToBuy from './components/HowToBuy/HowToBuy';
import Roadmap from './components/RoadMap/Roadmap';
import Aijockercat from './components/AigcVisitorCounter/Aijockercat';
import Banner from './components/Banner/Banner';

import Footer from './components/Footer/Footer';

function App() {
  // const cloudRef = useRef(null);

  // useEffect(() => {
  //   const handleScroll = () => {
  //     const scrollPosition = window.scrollY; // Get the vertical scroll position
  //     const offset = scrollPosition * 0.1; // Adjust this multiplier for movement speed
  //     if (cloudRef.current) {
  //       cloudRef.current.style.transform = `translateX(-50%) translateY(${offset}px)`;
  //     }
  //   };

  //   window.addEventListener('scroll', handleScroll);
  //   return () => {
  //     window.removeEventListener('scroll', handleScroll);
  //   };
  // }, []);

  return (
    <div className="App">
      <Header />
      {/* <HeroSection />   */}
      {/* <img
        ref={cloudRef}
        src={cloudImage}
        alt="Cloud"
        className="global-cloud-overlay"
      /> */}
      
      {/* <MovingStrip /> */}
      <HeroSection1 />
      <MovingStrip />
      <Banner />
      <Roadmap />
      <WhoWeAre />
      <HowToBuy />
      <Aijockercat />
      <Footer />
      {/* <MovingStrip />
     
      <MovingStrip /> */}
    </div>
  );
}

export default App;
