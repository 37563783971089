import React from 'react';
import './MovingStrip.css';
import lineItem1 from '../../assets/images/cat5-01-01-01iii.png';

const MovingStrip = () => {
  return (
    <div className="moving-strip">
      <div className="strip-content">
        <img src={lineItem1} alt="Line Item" className="strip-image" />
        <span className="strip-text">AiJokerCat</span>
        <img src={lineItem1} alt="Line Item" className="strip-image" />
        <span className="strip-text">AiJokerCat</span>
        <img src={lineItem1} alt="Line Item" className="strip-image" />
        <span className="strip-text">AiJokerCat</span>
        <img src={lineItem1} alt="Line Item" className="strip-image" />
        <span className="strip-text">AiJokerCat</span>
        <img src={lineItem1} alt="Line Item" className="strip-image" />
        <span className="strip-text">AiJokerCat</span>
        <img src={lineItem1} alt="Line Item" className="strip-image" />
        <span className="strip-text">AiJokerCat</span>
        <img src={lineItem1} alt="Line Item" className="strip-image" />
        <span className="strip-text">AiJokerCat</span>
        <img src={lineItem1} alt="Line Item" className="strip-image" />
        <span className="strip-text">AiJokerCat</span>
        <img src={lineItem1} alt="Line Item" className="strip-image" />
        <span className="strip-text">AiJokerCat</span>
        <img src={lineItem1} alt="Line Item" className="strip-image" />
        <span className="strip-text">AiJokerCat</span>
        <img src={lineItem1} alt="Line Item" className="strip-image" />
        <span className="strip-text">AiJokerCat</span>
        <img src={lineItem1} alt="Line Item" className="strip-image" />
        <span className="strip-text">AiJokerCat</span>
      </div>
    </div>
  );
};

export default MovingStrip;
