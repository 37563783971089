import React, { useEffect } from "react";
const VisitorCounter = () => {
  useEffect(() => {
    console.log("VisitorCounter component mounted.");
    // Dynamically load the visitor counter script
    const script = document.createElement("script");
    script.src = "https://www.freevisitorcounters.com/auth.php?id=3b275dac654ee14973c7140e7ea6c309981a8729";
    script.async = true;
    // Log when the script is about to be appended
    console.log("Appending the first visitor counter script...");
    document.body.appendChild(script);
    // Log when the script is loaded
    script.onload = () => {
      console.log("First visitor counter script loaded successfully.");
      // Attempt to log the number of visitors (check if the counter is available)
      setTimeout(() => {
        const visitorCount = document.querySelector("#free-visitor-counter span"); // Adjust based on actual element
        if (visitorCount) {
          console.log("Visitor count from the first script: ", visitorCount.textContent);
        } else {
          console.log("Visitor count not found from the first script.");
        }
      }, 3000); // Delay to ensure counter loads
    };
    // You can also add another script if necessary
    const script2 = document.createElement("script");
    script2.src = "https://www.freevisitorcounters.com/en/home/counter/1276940/t/3";
    script2.async = true;
    // Log when the second script is about to be appended
    console.log("Appending the second visitor counter script...");
    document.body.appendChild(script2);
    // Log when the second script is loaded
    script2.onload = () => {
      console.log("Second visitor counter script loaded successfully.");
      // Attempt to log the number of visitors from the second counter
      setTimeout(() => {
        const visitorCount2 = document.querySelector("#free-visitor-counter span"); // Adjust based on actual element
        if (visitorCount2) {
          console.log("Visitor count from the second script: ", visitorCount2.textContent);
        } else {
          console.log("Visitor count not found from the second script.");
        }
      }, 3000); // Delay to ensure counter loads
    };
    // Cleanup the script when the component unmounts
    return () => {
      console.log("Cleaning up the scripts...");
      document.body.removeChild(script);
      document.body.removeChild(script2);
    };
  }, []);
  return (
    <div className="counter-container">
      <div id="free-visitor-counter"></div>
    </div>
  );
};
export default VisitorCounter;