// import React from 'react';
// import './WhoWeAre.css';
// // import cloudImage from '../../assets/images/clound.avif'; // Replace with the actual path
// import smallCloudImage from '../../assets/images/smallcloud.avif'; // Replace with the actual path

// const WhoWeAre = () => {
//   return (
//     <div className="who-we-are-section">
//       {/* Cloud image overlaying the section */}
//       {/* <img src={cloudImage} alt="Cloud" className="who-we-are-cloud-overlay" /> */}

//       {/* Content section */}
//       <div className="who-we-are-content">
//         <h2 className="who-we-are-heading">Who's $ Mewster?</h2>
//         <p className="who-we-are-paragraph">
//           "Mewster is an AI-powered, playful, and positive <br></br>cat personality created to bring humor and engagement to the crypto space. <br></br>
//           Through a unique combination of text and audio jokes, <br></br>Mewster interacts with users on Twitter in a fun, light-hearted way. <br></br>
//           The goal is to make the crypto community smile while providing <br></br>a novel meme coin experience with voice interactions and humorous <br>
//           </br>commentary on trending topics.Mewster is an AI-powered, playful, <br></br>and positive cat personality created to bring humor and engagement to<br></br>
//           the crypto space. Through a unique combination of text and audio jokes, Mewster interacts with users on Twitter in a fun, light-hearted way. The goal is to make the crypto community smile while providing a novel meme coin experience with voice interactions and humorous commentary on trending topics.
//         </p>
//       </div>

//       {/* Image below the section */}
//       <img src={smallCloudImage} alt="Small Cloud" className="who-we-are-below-img" />
//     </div>
//   );
// };

// export default WhoWeAre;

// import React from 'react';
// import './WhoWeAre.css';
// import ParticleBackground from '../HeroSection1/ParticleSnowEffect';
// import headingImage from '../../assets/images/text (2).png'; // Replace with the actual path
// import bottomImage from '../../assets/images/3 in 1 (1).png'; // Replace with the actual path

// const WhoWeAre = () => {
//   return (
//     <div className="who-we-are-section">
//        <ParticleBackground />
//       {/* Background image */}
//       <div className="who-we-are-bg">
//         {/* Heading image */}
//         <img src={headingImage} alt="Heading" className="who-we-are-heading-img" />
//         {/* Bottom image */}
//         <img src={bottomImage} alt="Bottom" className="who-we-are-bottom-img" />
//       </div>
//     </div>
//   );
// };

// export default WhoWeAre;

import React from "react";
import "./WhoWeAre.css";
import ParticleBackground from "../HeroSection1/ParticleSnowEffect";
import headingImage from "../../assets/images/text (2).png";
import bottomImage from "../../assets/images/Group 22 (3).png";
import bottomImageWithoutBorder from "../../assets/images/globe and cat.png";
const WhoWeAre = () => {
  return (
    <div className="who-we-are-section">
      <ParticleBackground />
      {/* Background image */}
      <div className="who-we-are-bg">
        {/* Heading image */}
        <img
          src={headingImage}
          alt="Heading"
          className="who-we-are-heading-img"
        />
        {/* Bottom image */}
        {/* <img src={bottomImage} alt="Bottom" className="who-we-are-bottom-img" /> */}
        <img src={bottomImage} alt="Bottom" className="who-we-are-bottom-img" />
        <img
          src={bottomImageWithoutBorder}
          alt="Bottom"
          className="who-we-are-bottom-img-border"
        />
        <div className="text-overlay image-frame">
          <p>
            A cryptocurrency like no other, JokerCat Coin combines cutting-edge
            blockchain technology with a playful vibe.
          </p>
          <p>
            It's fast, secure, eco-friendly, and perfect for anyone — whether
            you're a crypto pro or just starting out.
          </p>
        </div>
      </div>
    </div>
  );
};
export default WhoWeAre;